import {useEffect, useState} from 'react'
import { inject, observer } from 'mobx-react'
import { useRouter, withRouter } from 'next/router'
import { isNumber, transformPathname } from '../../../utils/misc'
import { FormData, FormDataError, PaymentTypes } from '../../../entities/moneyDonation'

import styles from './index.module.scss'
import { isExceptionPage } from './utils'

const paymentTypes: PaymentTypes[] = [
    {id: 0, type: 'monthly', label: 'Ежемесячно'},
    {id: 1, type: 'once', label: 'Разово'},
]

const donationSums = [
    {id: 0, sum: 300},
    {id: 1, sum: 500},
    {id: 2, sum: 1000},
    {id: 3, sum: 3000},
]

const MainDonationForm = (props) => {
    const { authStore, uiStore, fundraisingStore } = props;
    const { user } = authStore;
    const router = useRouter();
    const { type, sum, email} = router.query;

    const getData = () => {
        authStore
            ?.fetchCurrentUser()
            .catch((err) => console.log(err));
        if(user) {
            fundraisingStore
                ?.getPayments()
                .then((res) => {
                    fundraisingStore?.setPaymentsCount(res?.count || 0);
                })
        }
    };

    const [formData, setFormData] = useState<FormData>({
        payment_type: 'monthly',
        sum: 300,
        payment_service: 'bank',
        comment: "",
        firstname: user?.first_name || "",
        lastname: user?.last_name || "",
        email: user?.email || "",
        offer_agree: true,
        personal_agree: true,
        donater_status: false,
        payment_display_status: false
    });
    const [error, setError] = useState<FormDataError>({
        sum: false,
        firstname: false,
        email: false,
        offer_agree: false,
        personal_agree: false
    });
    const [infoTextOpen, setInfoTextOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);

    const firstStageValid = formData.sum >= 10 && formData.sum <= 1000000;
    const thridStageValid = formData.firstname 
        && formData.email 
        && formData.offer_agree 
        && formData.personal_agree;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        if(type) {
            openWidget();
        }
        getData();

        return () => {
            if (uiStore.needSendSendsay && fundraisingStore.paymentsCount === 0) {
                (window['sndsyApiOnReady'] = window['sndsyApiOnReady'] || []).push(function () {
                    sndsyApi?.ssecEvent('VIEW_PRODUCT',
                    [{ "id": "donate", "price": 0, "name": "name", "vendor": "vendor", "picture": [""], "url": "https://donorsearch.org/" }],
                    { email: user?.email ? user?.email : undefined });
                });
                typeof sndsyApi != 'undefined' && sndsyApi.runQueue();
            }
        }
    }, []);

    const submitForm = () => {
        uiStore.setNeedSendSendsay(true);
    
        const isFirstStageValid = firstStageValid;
        if (!isFirstStageValid) {
            setError({ ...error, sum: true });
        } else {
            setError({ ...error, sum: false });
        }

        const isThirdStageValid = thridStageValid && emailRegex.test(formData.email);
        if (!isThirdStageValid) {
            setError({
                ...error,
                firstname: !formData.firstname,
                email: !emailRegex.test(formData.email),
                offer_agree: !formData.offer_agree,
                personal_agree: !formData.personal_agree
            });
        } else {
            setError({
                ...error,
                firstname: false,
                email: false,
                offer_agree: false,
                personal_agree: false
            });
        }
    
        if (isFirstStageValid && isThirdStageValid) {
            openWidget();
        }
    };

    const handleChangeDonationSum = (e) => {
        uiStore.setNeedSendSendsay(true);

        if(!infoTextOpen) {
            setInfoTextOpen(!infoTextOpen);
        }
        let sum;
        if(e.target.value.length === 1) {
            sum = e.target.value;
        } else if(e.target.value[e.target.value.length - 1] !== '₽') {
            sum = e.target.value.slice(0, e.target.value.length - 2);
        } else {
            sum = e.target.value.slice(0, e.target.value.length - 1);
        }
        const newVal = e.target.value.split('₽').slice(1)[0];
        let newSum;
        if(sum.length == 0) {
            sum = '0';
        }
        if(isNumber(sum)) {
            newSum = sum;
            if(isNumber(newVal)) {
                newSum = newSum.trim() + newVal.trim();
            }
            setFormData({...formData, sum: +newSum});
        }
        setError({...error, sum: +newSum < 10 || +newSum > 1000000});
    }

    const handleValueChange = (e) => {
        const id = e.target.id
        setFormData({...formData, [id]: e.target.value})
        setError({
            ...error, 
            [id]: id === 'email' ? !emailRegex.test(e.target.value) : !e.target.value
        });
    }

    const handleCheckChange = (id, checked) => {
        if(id === "donater_status") {
            setFormData({...formData, [id]: checked, payment_display_status: false})
        } else if (id === "payment_display_status") {
            setFormData({...formData, [id]: checked, donater_status: false})
        } else {
            setFormData({...formData, [id]: checked})
        }
        setError({...error, [id]: !checked})
    }

    function openWidget () {
        // Получаем данные из формы
        let firstName = formData.firstname;
        let lastName = formData.lastname;
        let userEmail = email ? email : formData.email;
        let comment = formData.comment
        let amount = sum ? Number(sum) : formData.sum;
        let isRecurrent = formData.payment_type === 'monthly';
        let donater_status = formData.donater_status;
        let payment_display_status = formData.payment_display_status

        if (type) {
            isRecurrent = type === 'recurent'
        }
      
        // Создаем виджет и передаем данные из формы
        let widget = new cp.CloudPayments({
            sbpSupport: isRecurrent ? false : true,
        });

        // Создаем объект данных для передачи в виджет
        let widgetData = {
            publicId: process.env.NEXT_PUBLIC_CD_KEY,
            description: 'Пожертвование на уставную деятельность',
            amount: amount,
            currency: 'RUB',
            accountId: userEmail,
            email: userEmail,
            skin: 'mini',
            autoClose: 3,
            data: {
                collect_id: undefined,
                firstName: firstName,
                lastName: lastName,
                email: userEmail,
                comment: comment,
                user_id: user?.id,
                donater_status: donater_status ? 'anonym' : 'not_anonym',
                payment_display_status: payment_display_status ? 'hidden' : 'visible',
                utm_source: transformPathname(window.location.pathname),
                utm_campaign: 'widjet',
                utm_term: '',
                utm_medium: 'ds',
                utm_content: '',
            }
        };
      
        // Если isRecurrent установлен как true, добавляем параметры для рекуррентных платежей
        if (isRecurrent) {
          widgetData.description = 'Пожертвование на уставную деятельность'
          widgetData.data.CloudPayments = {
            CustomerReceipt: '', // чек для первого платежа
            recurrent: {
              interval: 'Month',
              period: 1,
              customerReceipt: '', // чек для регулярных платежей
            },
          };
        }
      
        widget.pay('charge', widgetData,
        {
            onSuccess: function (options) { // success
                const tag = isRecurrent ? 'donate_recurrent' : 'donate_once';
                const amountDouble = amount.toFixed(2);
                ym(process.env.NEXT_PUBLIC_YANDEX_KEY,'reachGoal',tag,{order_price: amountDouble, currency: 'RUB'});
                setStatus(true)
                setFormData({
                    payment_type: 'monthly',
                    sum: 300,
                    payment_service: 'bank',
                    comment: "",
                    firstname: user?.first_name || "",
                    lastname: user?.last_name || "",
                    email: user?.email || "",
                    offer_agree: true,
                    personal_agree: true,
                    donater_status: false,
                    payment_display_status: false
                })
            },
            onFail: function (reason, options) { // fail
                setStatus(false)
            }
        });
    };

    if(isExceptionPage(router.asPath)) return null;

    return (
        <div className="container">
            <div className={`${styles.FormBlock}`}>
                <p className={styles.FormBlock_Title}>{ !status ? 'Поддержать развитие донорства' : 'Спасибо за вашу помощь!'}</p>
                {
                    !status ?
                    <form className={`${styles.Form}`} action="#">
                    <div className={styles.FirstLine}>
                        <div>
                            <p className={styles.Label}>Частота</p>
                            <div className={styles.PaymentTypes}>
                            {
                                paymentTypes.map(el => (
                                <div
                                    key={el.id}
                                    className={`${styles.PaymentTypes__Type} ${formData.payment_type === el.type && styles.PaymentTypes__TypeActive}`}
                                    onClick={() => {setFormData({...formData, payment_type: el.type});  uiStore.setNeedSendSendsay(true);}}
                                >
                                    <span>{el.label}</span>
                                </div>
                                ))
                            }
                            </div>
                        </div>
                        <div>
                            <p className={styles.Label}>Сумма пожертвования</p>
                            <div className={styles.Sums}>
                            <div className={styles.DonationSums}>
                                {
                                donationSums.map(el => (
                                    <div 
                                    key={el.id}
                                    className={`${styles.DonationSums__Sum} ${el.sum === formData.sum && styles.DonationSums__SumActive}`}
                                    onClick={() => {setFormData({...formData, sum: el.sum}); setError({...error, sum: false});  uiStore.setNeedSendSendsay(true);}}
                                    >
                                    <span>{el.sum} ₽</span>
                                    </div>
                                ))
                                }
                            </div>
                            <div className={styles.DonationInputSum}>
                                <input
                                className={`${error?.sum ? styles.ErrorInput : ""} ${styles.DonationInputSum__Input}`}
                                type="text" 
                                value={`${formData.sum} ₽`} 
                                maxLength={9}
                                onChange={(e) => handleChangeDonationSum(e)}
                                />
                            </div>
                            {
                                infoTextOpen &&
                                <p
                                    className={`${error?.sum ? styles.ErrorText : ""} ${styles.DonationInputSum__Text}`} 
                                >
                                    {formData.sum > 1000000 ? 'Максимальная сумма пожертвования — 1000000 ₽' : 'Минимальная сумма пожертвования — 10 ₽'}
                                </p>
                            }
                            </div>
                        </div>
                        <div className={styles.ContactBlock}>
                            <div className={styles.ContactBlock__Input}>
                            <label htmlFor="firstname">Имя <span>*</span></label>
                            <input 
                                className={`${error?.firstname ? styles.ErrorInput : ""}`} 
                                type="text" 
                                id="firstname"
                                placeholder='Имя'
                                value={formData.firstname}
                                onChange={(e) => handleValueChange(e)}
                                disabled={!!user?.donation_agg?.count}
                            />
                            </div>
                            <div className={styles.ContactBlock__Input}>
                            <label htmlFor="lastname">Фамилия</label>
                            <input 
                                type="text" 
                                id="lastname"
                                placeholder='Фамилия'
                                value={formData.lastname}
                                onChange={(e) => handleValueChange(e)}
                                disabled={!!user?.donation_agg?.count}
                            />
                            </div>
                            <div className={styles.ContactBlock__Input}>
                            <label htmlFor="email">Электронная почта <span>*</span></label>
                            <input 
                                className={`${error?.email ? styles.ErrorInput : ""}`} 
                                type="email" 
                                id="email"
                                placeholder='example@mail.ru'
                                value={formData.email}
                                onChange={(e) => handleValueChange(e)}
                            />
                            </div>
                        </div>
                    </div>
                    <div className={styles.SecondLine}>
                        <div className={styles.Comment}>
                            <label htmlFor="comment">Комментарий</label>
                            <textarea 
                                id="comment"
                                placeholder='Здесь вы можете оставить комментарий или свои пожелания'
                                cols={30}
                                rows={10} 
                                maxLength={256}
                                value={formData.comment}
                                onChange={(e) => setFormData({...formData, comment: e.target.value})}
                            />
                            <span>{formData.comment.length}/256</span>
                        </div>
                        <div className={styles.CheckBoxes}>
                            <div>
                                <input 
                                    className={`${error?.offer_agree ? styles.ErrorCheckBox : ""}`} 
                                    type="checkbox" 
                                    id='offer_agree' 
                                    onChange={() => handleCheckChange('offer_agree', !formData.offer_agree)}
                                    checked={formData.offer_agree}
                                />
                                <label htmlFor='offer_agree'></label>
                                <p>Соглашаюсь с <a href='/offer' target='_blank'>офертой</a> <span>*</span></p>
                            </div>
                            <div>
                                <input 
                                    className={`${error?.personal_agree ? styles.ErrorCheckBox : ""}`} 
                                    type="checkbox" 
                                    id='personal_agree'
                                    onChange={() => handleCheckChange('personal_agree', !formData.personal_agree)}
                                    checked={formData.personal_agree}
                                />
                                <label htmlFor='personal_agree'></label>
                                <p>Соглашаюсь на обработку моих <a href='/confidential' target='_blank'>персональных данных</a> <span>*</span></p>
                            </div>
                            <div>
                                <input 
                                    type="checkbox" 
                                    id='donater_status' 
                                    onChange={() => handleCheckChange('donater_status', !formData.donater_status)}
                                    checked={formData.donater_status}
                                />
                                <label htmlFor='donater_status'></label>
                                <p>Скрыть мои ФИО в ленте пожертвований</p>
                            </div>
                            <div>
                                <input 
                                    type="checkbox" 
                                    id='payment_display_status'
                                    onChange={() => handleCheckChange('payment_display_status', !formData.payment_display_status)}
                                    checked={formData.payment_display_status}
                                />
                                <label htmlFor='payment_display_status'></label>
                                <p>Скрыть сумму моего пожертвования в ленте</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.DonationButton}`}>
                        <button 
                            type="button" 
                            onClick={() => submitForm()}
                            onSubmit={() => submitForm()}
                        >
                            Пожертвовать {formData.sum} ₽
                        </button>
                    </div>
                    </form>
                    :
                    <div className={`${styles.Form} ${styles.FormSuccess}`}>
                        <button 
                            type="button" 
                            onClick={() => setStatus(false)}
                            onSubmit={() => setStatus(false)}
                        >
                            Пожертвовать еще
                        </button>
                    </div>
                }   
        </div>
    </div>
  )
}

export default inject(
    "authStore",
    "uiStore",
    "fundraisingStore"
)(observer(withRouter(MainDonationForm)));